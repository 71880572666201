import {Component, DestroyRef, inject, OnInit, viewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {JtmComponentsModule, NavigationJ4Component} from '@jtm/jtm-components';
import {NavigationBarService} from 'core/services';

@Component({
  selector: 'j4-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
  standalone: true,
  imports: [JtmComponentsModule]
})
export class NavigationComponent implements OnInit {
  navigationBarService = inject(NavigationBarService);
  destroyRef = inject(DestroyRef);
  navigationJ4 = viewChild(NavigationJ4Component);

  ngOnInit(): void {
    // eslint-disable-next-line rxjs/no-ignored-subscription
    this.navigationBarService.currentBreadcrumb$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((label: string) => {
      this.navigationJ4()?.selectItemByLabel(label);
    });
  }
}
