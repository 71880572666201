import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class NavigationBarService {
  private navigationChange = new Subject<string>();
  readonly currentBreadcrumb$ = this.navigationChange.asObservable();

  updateNavigation(navItemLabel: string): void {
    this.navigationChange.next(navItemLabel);
  }
}
