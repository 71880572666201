import {Component, EventEmitter, OnInit, Output, signal} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {EMPTY, firstValueFrom} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {MonitorFacade} from '../../../services/monitor/monitor.facade';
import {WidgetsIds} from '../../dashboard/dashboard.component.dto';
import {CustomerDashboard, IWidgets, chevron_right, expand_more} from './tree-checklist.component.data';

@Component({
  selector: 'j4-tree-checklist',
  templateUrl: './tree-checklist.component.html',
  styleUrls: ['./tree-checklist.component.less']
})
export class TreeChecklistComponent implements OnInit {
  @Output() public newImagEvent = new EventEmitter<string>();
  private userDashboardItems = signal<string[]>([]);
  private widgetsToSave: string[] = [];
  public treeControl = new NestedTreeControl<IWidgets>(node => node.widgets);
  public dataSource = new MatTreeNestedDataSource<IWidgets>();

  constructor(
    private monitorFacade: MonitorFacade,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral('expand-more', sanitizer.bypassSecurityTrustHtml(expand_more));
    iconRegistry.addSvgIconLiteral('chevron-right', sanitizer.bypassSecurityTrustHtml(chevron_right));
  }

  async ngOnInit(): Promise<void> {
    const dashboard = await firstValueFrom(this.monitorFacade.getUserDashboard$());
    this.userDashboardItems.set(dashboard);
    CustomerDashboard.forEach(category => {
      category.widgets?.forEach(widget => {
        if (this.userDashboardItems().includes(WidgetsIds[widget.id as number])) {
          widget.selected = true;
        }
      });

      CustomerDashboard.forEach(node => {
        this.setParent(node, null!);
      });
    });

    this.dataSource.data = CustomerDashboard;
  }

  public hasChild = (_: number, node: IWidgets): boolean => !!node.widgets && node.widgets.length > 0;

  public itemToggle(checked: boolean, node: IWidgets): void {
    node.selected = checked;
    if (node.widgets) {
      node.widgets.forEach(child => {
        this.itemToggle(checked, child);
      });
    }
    this.checkParents(node);
  }

  public saveSelect(): void {
    const selectedWidgets = this.dataSource.data.reduce(
      (acc: string[], node: IWidgets) =>
        acc.concat(
          this.treeControl
            .getDescendants(node)
            .filter(widget => widget.selected)
            .map(selectedWidget => WidgetsIds[selectedWidget.id as number])
        ),
      []
    );

    this.monitorFacade
      .getUserDashboard$()
      .pipe(
        map(dashboard => {
          // we filter the widgets this way to preserve the order
          const widgetsToPreserve = dashboard.filter(widgetId => selectedWidgets.includes(widgetId));

          // we add the selected widgets in edit widget component
          const widgetsToAdd = selectedWidgets.filter(widgetId => !widgetsToPreserve.includes(widgetId));

          const widgetsToSave = [...widgetsToPreserve, ...widgetsToAdd];
          this.widgetsToSave = widgetsToSave;
          return widgetsToSave;
        }),
        switchMap(widgetsToSave =>
          this.monitorFacade.saveWidgetsByNames(widgetsToSave).pipe(
            tap(result => {
              console.log('widget selection', result);
              location.reload();
            }),
            catchError(error => {
              console.error('error while editing dashboard', error);
              return EMPTY;
            })
          )
        ),
        take(1)
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription
      .subscribe(() => {}, console.error);
  }

  public showSelectImag(id: number): void {
    if (!WidgetsIds[id]) {
      return;
    }
    const nameId = WidgetsIds[id].toLowerCase();
    this.newImagEvent.emit(nameId);
  }

  private setParent(node: IWidgets, parent: IWidgets): void {
    node.parent = parent;
    if (node.widgets) {
      node.widgets.forEach(childNode => {
        this.setParent(childNode, node);
      });
    }
    this.checkParents(node);
  }

  private checkParents(node: IWidgets): void {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every(child => child.selected);
      node.parent.indeterminate = descendants.some(child => child.selected);
      this.checkParents(node.parent);
    }
  }
}
