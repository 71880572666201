import {v4 as UUID} from 'uuid';
import moment from 'moment';
import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';

export class ErrorItem {
  constructor(
    public parameter: string,
    public message: string
  ) {}
}
/**
 * General class for describing errors.
 */
export class ErrorDescriptor {
  /**
   * The error's identification string.
   */
  public id: string;

  constructor(
    public error: string,
    public errorCode: string,
    public status: number,
    public errors: Array<ErrorItem> = [],
    public timestamp: number = +moment().format('x')
  ) {
    this.id = UUID();
  }
  /**
   * Generates unknown error with a default status.
   * @param error The input error that could not be processed.
   * @returns {ErrorDescriptor} A default unknown ErrorDescriptor object.
   */
  public static unknownError(error: any): ErrorDescriptor {
    return new ErrorDescriptor(`Unknown error: ${error.toString()}`, BaseErrorCodes.UNKNOWN_ERROR_IN_FACADE.toString(), 0);
  }

  /**
   * Returns if error code TOKEN_INVALID
   * @returns {boolean}
   */
  public get isTokenInvalid(): boolean {
    return this.errorCode === BaseErrorCodes.TOKEN_INVALID;
  }

  /**
   * Returns error as string
   * @returns {string}
   */
  public toString(): string {
    return `${this.error}, ErrorCode: ${this.errorCode}, Status: ${this.status}, Timestamp: ${this.timestamp}`;
  }
}
