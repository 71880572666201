import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {InfoService} from 'public-shared/services/info/info.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ExperimentEndpoints} from 'shared/services/endpoint-constants';
import {ErrorCodes} from 'shared/services/error/error-codes';
import {LegacyExperimentDetail} from 'shared/services/experiments/experiments.dto';
import {LegacyTaggingTemplate, LegacyTaggingTemplateWrapper} from 'shared/services/experiments/legacy-tagging-template.dto';

@Injectable()
/**
 * The service responsible for sending and receiving Experiment data between the client and server.
 */
export class LegacyExperimentsService extends BaseService {
  /**
   * A default value that can be used for the add-experiment page.
   */
  public experimentDefaultValue: LegacyExperimentDetail | undefined;

  /**
   * Used for watching changes in tagging templates and notifying components about them.
   */
  public legacyTaggingTemplatesChanged = new BehaviorSubject(null);

  /**
   * Used for watching changes in experiments and notifying components about them.
   */
  public experimentsChanged = new BehaviorSubject(null);

  constructor(
    protected override http: HttpClient,
    protected infoService: InfoService
  ) {
    super(http);
    this.baseUrl = ExperimentEndpoints.BASE_V1;
  }

  public handleError(error: ErrorDescriptor): void {
    if (
      error.errorCode === ErrorCodes.NO_RESULT_FOUND ||
      error.errorCode === ErrorCodes.DUPLICATE_ENTITY ||
      error.errorCode === ErrorCodes.INVALID_INPUT_DATA ||
      error.errorCode === ErrorCodes.ACTION_NOT_ALLOWED ||
      error.errorCode === ErrorCodes.DATASET_MAINTENANCE ||
      error.errorCode === ErrorCodes.UNKNOWN_ERROR ||
      error.errorCode === ErrorCodes.NOT_READABLE_REQUEST
    ) {
      this.infoService.show(error.error, true);
    } else {
      throw error;
    }
  }

  public addTaggingTemplate$(template: LegacyTaggingTemplate): Observable<number> {
    return this.post$<number>(ExperimentEndpoints.LEGACY_TAGGING_TEMPLATES, template);
  }

  public modifyTaggingTemplate$(taggingTemplate: LegacyTaggingTemplateWrapper): Observable<void> {
    return this.put$<void>(
      //@ts-ignore
      ExperimentEndpoints.LEGACY_TAGGING_TEMPLATE(taggingTemplate.taggingTemplate?.id),
      taggingTemplate.taggingTemplate
    );
  }
}
