import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IPortalConfig, PORTAL_DATA, PortalApiType} from '@jumio/portals.core';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {MerchantEndpoints} from 'shared/services/endpoint-constants';
import {DataRetentionInit, DataSettingsDataRetention} from './data-settings-data-retention.dto';
import {DataSettingsEnabledFields, UpdateDataSettingsEnabledFields} from './data-settings-enabled-fields.dto';
import {DataSettingsMandatoryFields} from './data-settings-mandatory-fields.dto';
import {DataSettingsOptionalFields} from './data-settings-optional-fields.dto';
import {DataSettingsScreeningEditFields, DataSettingsScreeningFields} from './data-settings-screening.dto';
import {DataSettingsWatchlistScreening} from './data-settings-watchlist-screening.dto';

/**
 * Handles merchant data settings requests.
 */
@Injectable()
export class MerchantDataSettingsService extends BaseService {
  public static exactMatchCode = '-1';

  // service is used on (UP and CP) OR AP
  public isAdminPortal: boolean;

  constructor(
    protected override http: HttpClient,
    @Inject(PORTAL_DATA) private portalConfig: IPortalConfig
  ) {
    super(http);
    this.isAdminPortal = this.portalConfig.apiType === PortalApiType.AP;
    this.baseUrl = this.isAdminPortal ? MerchantEndpoints.BASE : BaseEndpoints.SETTINGS;
    this.prefix = this.isAdminPortal ? MerchantEndpoints.AUI_PREFIX : MerchantEndpoints.MUI_PREFIX;
  }

  // Options from 0% to 100% with steps of 10
  public static getFuzzinessOptions(): CodeLabel[] {
    const fuzziness = Array.from(Array(11).keys()).map(key => ({code: key * 10 + '', label: `${key || ''}0 %`}));
    fuzziness.unshift({code: MerchantDataSettingsService.exactMatchCode, label: 'Exact Match'});
    return fuzziness;
  }

  /**
   * Adds an appropriate prefix for the endpoint URL depending on the given app (MUI or AUI).
   */
  public prefix: any = () => {};

  /**
   * Returns the mandatory fields of the merchant's data settings.
   * @param {string} id The merchant's id.
   * @returns {Observable<DataSettingsMandatoryFields>} The result in an Observable.
   */
  public getMandatoryFields$(id: string): Observable<DataSettingsMandatoryFields> {
    return this.get$<DataSettingsMandatoryFields>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_MANDATORY);
  }

  /**
   * Sends a request to modify the merchant's mandatory fields settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsMandatoryFields} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyMandatoryFields$(id: string, settings: DataSettingsMandatoryFields, password: string, passCode?: string): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_MANDATORY,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the optional fields of the merchant's data settings.
   * @param {string} id The merchant's id.
   * @returns {Observable<DataSettingsOptionalFields>} The result in an Observable.
   */
  public getOptionalFields$(id: string): Observable<DataSettingsOptionalFields> {
    return this.get$<DataSettingsOptionalFields>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_OPTIONAL);
  }

  /**
   * Sends a request to modify the merchant's optional fields settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsMandatoryFields} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyOptionalFields$(id: string, settings: DataSettingsOptionalFields, password: string, passCode?: string): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_OPTIONAL,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the screening fields of the merchant's data settings.
   * @param {string} id The merchant's id.
   * @returns {Observable<DataSettingsScreeningFields>} The result in an Observable.
   */
  public getScreeningFields$(id: string): Observable<DataSettingsScreeningFields> {
    return this.get$<DataSettingsScreeningFields>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_SCREENING);
  }

  /**
   * Sends a request to modify the merchant's screeniing fields settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsScreeningEditFields} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyScreeningFields$(
    id: string,
    settings: DataSettingsScreeningEditFields,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_SCREENING,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the enabled fields of the merchant's data settings.
   * @param {string} id The merchant's id.
   * @returns {Observable<DataSettingsEnabledFields>} The result in an Observable.
   */
  public getEnabledyFields$(id: string): Observable<DataSettingsEnabledFields> {
    return this.get$<DataSettingsEnabledFields>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_ENABLED);
  }

  /**
   * Sends a request to modify the merchant's enabled fields settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsMandatoryFields} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyEnabledFields$(
    id: string,
    settings: UpdateDataSettingsEnabledFields,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_ENABLED, new EntityWithPassword(settings, password, passCode));
  }

  /**
   * Returns the init object of the data retention settings of a given merchant.
   * @returns {Observable<DataRetentionInit>} The result in an Observable.
   */
  public initDataRetention$(): Observable<DataRetentionInit> {
    return this.get$<DataRetentionInit>(MerchantEndpoints.DATA_SETTINGS_RETENTION_INIT);
  }

  /**
   * Returns the data retention settings of the merchant's data settings.
   * @param {string} id The merchant's id.
   * @returns {Observable<DataSettingsDataRetention>} The result in an Observable.
   */
  public getDataRetention$(id: string): Observable<DataSettingsDataRetention> {
    return this.get$<DataSettingsDataRetention>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_RETENTION);
  }

  /**
   * Sends a request to modify the merchant's data retention settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsMandatoryFields} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyDataRetention$(id: string, settings: DataSettingsDataRetention, password: string, passCode?: string): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_RETENTION,
      new EntityWithPassword(settings, password, passCode)
    );
  }

  /**
   * Returns the data to populate the Admin Portal / Customer/ Settings / Watchlist Screening page
   * @param {string} id The merchant's id.
   */
  public getWatchlistScreeningSettings$(id: string): Observable<DataSettingsWatchlistScreening> {
    return this.get$<DataSettingsWatchlistScreening>(this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_WATCHLIST_SCREENING);
  }

  /**
   * Sends a request to modify the merchant's watchlist screening settings.
   * @param {string} id The merchant's id.
   * @param {DataSettingsWatchlistScreening} settings The new settings object.
   * @param {string} password The merchant's password.
   * @param {string} passCode The merchant's security code.
   * @returns {Observable<void>} An empty Observable.
   */
  public modifyWatchlistScreeningSettings$(
    id: string,
    settings: DataSettingsWatchlistScreening,
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.put$<void>(
      this.prefix(id) + MerchantEndpoints.DATA_SETTINGS_WATCHLIST_SCREENING,
      new EntityWithPassword(settings, password, passCode)
    );
  }
}
