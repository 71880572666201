import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {CacheService} from 'shared/services/common/cache.service';
import {FraudEndpoints} from 'shared/services/endpoint-constants';
import {FraudFields} from 'shared/services/fraud/fraud-fields.dto';
import {IExcludeFraud, Fraud, FraudCsvUploadResult, FraudItem, FraudItemDetail, FraudUIType} from 'shared/services/fraud/fraud.dto';
import {IMarkApprovedRequest, MarkApprovedRequest} from 'shared/services/fraud/mark-approved-request.dto';
import {IMarkFraudRequest, MarkFraudRequest} from 'shared/services/fraud/mark-fraud-request.dto';

@Injectable()
export class FraudService extends BaseService {
  private FRAUD_FIELDS_CACHE_KEY = 'FRAUD_FIELDS';
  private _fraudUIType: FraudUIType | undefined;

  constructor(
    protected override http: HttpClient,
    private cache: CacheService
  ) {
    super(http);
  }

  public getFraud$(id: string): Observable<Fraud> {
    return this.get$<Fraud>(FraudEndpoints.FRAUD(id));
  }

  public initFraud$(): Observable<FraudFields> {
    const fn$ = this.get$<FraudFields>(FraudEndpoints.INIT);
    return this.cache.cacheFunction$<FraudFields>(this.FRAUD_FIELDS_CACHE_KEY, fn$);
  }

  public importCsv$(formData: FormData): Observable<FraudCsvUploadResult> {
    return this.post$<FraudCsvUploadResult>(FraudEndpoints.CSV, formData);
  }

  public updateCsv$(formData: FormData): Observable<FraudCsvUploadResult> {
    return this.post$<FraudCsvUploadResult>(FraudEndpoints.UPDATE_CSV, formData);
  }

  public markFraud$(markFraudRequest: MarkFraudRequest, fraudId: string): Observable<Fraud> {
    return this.post$<Fraud>(FraudEndpoints.MARK_FRAUD(fraudId), markFraudRequest);
  }

  public markFuzzyFraud$(markFraudRequest: IMarkFraudRequest, fraudId: string): Observable<Fraud> {
    return this.post$<Fraud>(FraudEndpoints.MARK_FUZZY_FRAUD(fraudId), markFraudRequest);
  }

  public markApproved$(comment: string, fraudId: string): Observable<Fraud> {
    const markApproved = new MarkApprovedRequest();
    markApproved.comment = comment;
    return this.post$<Fraud>(FraudEndpoints.MARK_APPROVED(fraudId), markApproved);
  }

  public markFuzzyApproved$(markApproved: IMarkApprovedRequest, fraudId: string): Observable<Fraud> {
    return this.post$<Fraud>(FraudEndpoints.MARK_FUZZY_APPROVED(fraudId), markApproved);
  }

  public excludeFraud$(scanId: string, excludeFraud: IExcludeFraud): Observable<FraudItemDetail> {
    return this.put$<FraudItemDetail>(FraudEndpoints.EXCLUDE_SCAN(scanId), excludeFraud);
  }

  public deleteFraud$(scanId: string, fraudId: string): Observable<Fraud> {
    return this.delete$<Fraud>(FraudEndpoints.DELETE(scanId, fraudId));
  }

  public editFraud$(scanId: string, item: FraudItem): Observable<FraudItemDetail> {
    return this.post$<FraudItemDetail>(FraudEndpoints.EDIT(scanId), item);
  }

  public addFraud$(scanId: string, item: FraudItem): Observable<Fraud> {
    return this.post$<Fraud>(FraudEndpoints.ADD(scanId), item);
  }

  public set fraudUIType(type: FraudUIType | undefined) {
    this._fraudUIType = type;
    this.baseUrl = this._fraudUIType?.baseUrl ?? '';
  }

  public get fraudUIType(): FraudUIType | undefined {
    return this._fraudUIType;
  }
}
