import {TableColumnProp} from '@swimlane/ngx-datatable';

/**
 * The Jumio backend base sort
 */
export class JumioSort {
  // Possible sort fields
  public static readonly UPDATED_INFO_CREATED_AT = 'updateInfoCreatedAt';
  public static readonly ID_SPEC_TYPE = 'idSpecType';
  public static readonly USER_NAME = 'userName';
  public static readonly NAME = 'name';
  public static readonly TRANSACTION_DATE = 'transactionDate';
  public static readonly TRANSACTION_CREATION_DATE = 'transactionCreationDate';
  public static readonly EMAIL = 'email';
  public static readonly REGISTRATION_DATE = 'registrationDate';
  public static readonly CREATED_AT = 'createdAt';
  public static readonly DATE = 'date';
  public static readonly INIT_DATE = 'initiatedAt';

  constructor(
    public field: TableColumnProp | undefined,
    public asc: boolean
  ) {}
}
