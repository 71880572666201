import {PublicHelpers} from 'public-shared/helpers/public-helpers';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {Permission} from '@jumio/portals.core';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

export class Helpers extends PublicHelpers {
  /**
   * Transforms an Enum into a CodeLabel which is useful for any kind of dropdowns
   * @param enumType - Which enum should be transformed
   * @param customOption - If a custom option is necessary then it can be provided
   * @param customOptionLocation - The previous customOption's location inside the dropdown.
   */
  public static EnumToCodeLabels(enumType: any, customOption?: CodeLabel, customOptionLocation?: 'top' | 'bottom'): Array<CodeLabel> {
    const codeLabels = Object.keys(enumType).map(key => ({code: key, label: enumType[key]}) as CodeLabel);
    if (customOption && customOptionLocation) {
      if (customOptionLocation === 'bottom') {
        codeLabels.push(customOption);
      } else {
        codeLabels.unshift(customOption);
      }
    }
    return codeLabels;
  }

  /**
   * Retrieves everything before the first question mark
   * @param url
   */
  public static getParamBeforeQuestionMark(url: string): string {
    if (!url) {
      return '';
    }
    const regex = /^(.*?)(?=\?|$)/gm;
    //@ts-ignore
    return regex.exec(url)[0] ?? '';
  }

  /**
   * Retrieves query params from url by name
   * @param name
   * @param url
   */
  public static getParameterByName(name: string, url: string): string | null {
    name = name.replace(/[[]]/g, '$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /**
   * Gives the boolean flag if sandbox features are to be enabled
   * @param contextHolder - which would hold the authorities for current session
   */
  public static isSandboxEnabled(contextHolder: SecurityContextHolder): boolean {
    return contextHolder.authorities.indexOf(Permission.VIEW_TAGGING_INFRA_SANDBOX_VERSION) >= 0;
  }

  /**
   * Transforms a number range into CodeLabels which is useful for any kind of dropdowns
   * @param from - From when the loop should go
   * @param to - Until when the loop should go
   * @constructor
   */
  public static NumberRangeToCodeLabels(from: number, to: number): Array<CodeLabel> {
    to++; // Array end value should be included
    return Array.from(Array(to).keys())
      .filter(key => key >= from)
      .map(key => ({code: key + '', label: key + ''}));
  }

  /**
   * True/False dropdown for various pages
   * @param trueLabel - Label text for the true value
   * @param falseLabel - Label text for the false value
   * @param falseFirst - False will be the first element of the dropdown instead of true
   */
  public static GenerateBooleanCodeLabels(trueLabel: string, falseLabel: string, falseFirst?: boolean): Array<CodeLabel> {
    const trueElement = {code: 'true', label: trueLabel};
    const falseElement = {code: 'false', label: falseLabel};

    return falseFirst ? [falseElement, trueElement] : [trueElement, falseElement];
  }

  /**
   * CodeLabels use string values even when they should be booleans.
   * This converts the boolean data coming from the backend to string data used by CodeLabels
   * Can be used generically as well.
   * @param value - Value which needs to be converted
   * @param exactMatch - If it is nor true neither false, null will be returned
   */
  public static FromBooleanToString(value: boolean | null | undefined, exactMatch?: boolean): string | null {
    if (exactMatch) {
      return value === true ? 'true' : value === false ? 'false' : null;
    }
    return value ? 'true' : 'false';
  }

  /**
   * CodeLabels use string values even when they should be booleans.
   * This converts the string data coming from CodeLabels to boolean data used by the backend
   * Can be used generically as well.
   * @param value - Value which needs to be converted
   * @param exactMatch - If it is nor 'true' neither 'false', null will be returned
   */
  public static FromStringToBoolean(value: string | null, exactMatch?: boolean): boolean | null {
    if (exactMatch) {
      return value === 'true' ? true : value === 'false' ? false : null;
    }
    return value === 'true';
  }

  /**
   * Checks whether the uploaded file is really CSV or text file.
   * @param file the uploaded file.
   * @returns {boolean}
   */
  public static isFileCsvOrText(file: any): boolean {
    if (file.type && file.type !== '') {
      return this.ACCEPTED_FILE_TYPES.indexOf(file.type) > -1;
    } else if (file.name) {
      const extension = this.getLastExtension(file.name);
      return extension === this.CSV_EXTENSION || extension === this.TXT_EXTENSION;
    } else {
      return false;
    }
  }

  public static isFileCsv(file: any): boolean {
    if (file.type && file.type !== '') {
      return ['text/csv'].indexOf(file.type) > -1;
    } else {
      return file.name && this.getLastExtension(file.name) === 'csv';
    }
  }

  /**
   * Checks whether the uploaded file is really PDF file.
   * @param file the uploaded file.
   * @returns {boolean}
   */
  public static isFilePDF(file: any): boolean {
    if (file.name) {
      const extension = this.getLastExtension(file.name);
      return extension === this.PDF_EXTENSION;
    } else {
      return false;
    }
  }

  /**
   * Returns the extension from the file's name.
   * @param {string} fileName The given file's name.
   * @returns {string} The extension from the file's name, if it's existing.
   */
  public static getLastExtension(fileName: string): string | null | undefined {
    const split = fileName.split('.');

    // Check if the filename has enough amount of parts after split
    // Files without extension and hidden files (e.g. .htaccess) will not be allowed
    if (split.length === 1 || (split.length === 2 && split[0] === '')) {
      return null;
    } else {
      return split.pop()?.toLowerCase();
    }
  }

  /**
   * Checks if the currently used browser is related to Internet Explorer or not
   */
  public static override isIEBrowser(): boolean {
    // TODO: W2A-1957 - link to some reference that explains why this regex matcher works
    return /msie\s|trident\//i.test(window.navigator.userAgent);
  }

  /**
   * Checks if the input string contains chinese characters or not
   * The provided regex is a summary combination of an existing plugin
   * https://github.com/alsotang/is-chinese/blob/master/ischinese.js
   */
  public static isChineseString(text: string): boolean {
    // eslint-disable-next-line max-len
    const REGEX_CHINESE =
      /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
    return REGEX_CHINESE.test(text);
  }

  /**
   * The list of accepted file types of the scanRef field.
   * @type {string[]}
   */
  private static readonly ACCEPTED_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel', 'text/plain'];
  private static readonly CSV_EXTENSION = 'csv';
  private static readonly TXT_EXTENSION = 'txt';
  private static readonly PDF_EXTENSION = 'pdf';
}
