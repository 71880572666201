import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
import {UserDetailsConstants} from './user-details.constants';
import {UserEditDetail, UserUpdateRequest} from './user.dto';
import {UserService} from './user.service';

/**
 * Defines a user operation
 */
export interface Operation {
  operation: (password: string | undefined, passcode?: string) => Observable<void>;
  text: UserDetailsConstants;
  confirmText: UserDetailsConstants;
  successText: UserDetailsConstants;
  permission?: string;
  onSet?: any;
  onModify?: any;
}

/**
 * Defines the different operations available to modify a user
 */
export interface UserOperations {
  UPDATE: Operation;
  ACTIVATE: Operation;
  DEACTIVATE: Operation;
  RESEND: Operation;
  UNLOCK: Operation;
  RESETTFA: Operation;
}

/**
 * Defines the different permissions used when modifying a user
 */
export interface UserDetailsPagePermissions {
  manageDataPermission?: string;
  manageRolePermission?: string;
  activatePermission?: string;
  deactivatePermission?: string;
  resendPermission?: string;
  resetTfaSecretPermission?: string;
  createPermission?: string;
}

@Injectable()
export abstract class AbstractUserOperationsService {
  constructor(
    protected userService: UserService,
    protected contextHolder: SecurityContextHolder
  ) {}

  abstract loadOperations(_params: Params, _permissions: UserDetailsPagePermissions, _user: UserEditDetail): any;

  /**
   * Returns true, if the current account status is DEACTIVATED.
   * @param {UserEditDetail} user The user object.
   * @returns {boolean} true, if the current account status is DEACTIVATED.
   */
  public isDeactivated(user: UserEditDetail): boolean {
    return this.isGivenStatus(user, UserDetailsConstants.DEACTIVATED_STATUS_CODE);
  }

  /**
   * Returns true, if the current account status is ACTIVATED.
   * @param {UserEditDetail} user The user object.
   * @returns {boolean} true, if the current account status is ACTIVATED.
   */
  public isActivated(user: UserEditDetail): boolean {
    return this.isGivenStatus(user, UserDetailsConstants.ACTIVATED_STATUS_CODE);
  }

  /**
   * Returns true, if the current account status is EXPIRED.
   * @param {UserEditDetail} user The user object.
   * @returns {boolean} true, if the current account status is EXPIRED.
   */
  public isExpired(user: UserEditDetail): boolean {
    return this.isGivenStatus(user, UserDetailsConstants.EXPIRED_STATUS_CODE);
  }

  /**
   * Returns true, if the current loggedin user is the same as the `user` param
   * @param {UserEditDetail} user The user object.
   * @returns {boolean} true, if the current loggedin user is the same as the `user` param
   */
  public isCurrentLoggedinUser(user: UserEditDetail): boolean {
    return this.contextHolder.user.email === user.email;
  }

  /**
   * Returns true/false given of the current account status.
   * @param {UserEditDetail} user The user object.
   * @param {string} statusCode The status we want to check.
   * @returns {boolean} True/false given of the current account status.
   */
  protected isGivenStatus(user: UserEditDetail, statusCode: string): boolean {
    return user.accountStatus?.code === statusCode;
  }

  /**
   * Creates a new DTO ready to be sent to the backend.
   * @returns {UserUpdateRequest} The new update DTO object.
   */
  protected makeUpdateDTO(user: UserEditDetail): UserUpdateRequest {
    return UserUpdateRequest.fromUserEditDetail(user);
  }
}

@Injectable()
export class UserOperationsService extends AbstractUserOperationsService {
  /**
   * Defines the different operations of the page.
   * Sets 'UPDATE' as the default operation.
   * @param {Params} params Parameters from the current route
   * @param {UserDetailsPagePermissions} permissions Current user edit/detail page permissions needed for each operation
   * @param {UserEditDetail} user The user object.
   * @returns {boolean} true, if the current account status is DEACTIVATED.
   */
  public loadOperations(params: Params, permissions: UserDetailsPagePermissions, user: UserEditDetail): UserOperations {
    return {
      UPDATE: {
        operation: (password, passcode) => this.userService.modifyUserDetails$(params['id'], this.makeUpdateDTO(user), password, passcode),
        text: UserDetailsConstants.UPDATE_LABEL,
        confirmText: UserDetailsConstants.UPDATE_CONFIRM,
        successText: UserDetailsConstants.UDPATE_SUCCESS,
        permission: `${permissions.manageDataPermission},${permissions.manageRolePermission}`,
        onSet: (): void => {},
        onModify: (): void => {}
      },
      ACTIVATE: {
        operation: (password, passcode) => this.userService.activateUser$(params['id'], password, passcode),
        text: UserDetailsConstants.ACTIVATE_LABEL,
        confirmText: UserDetailsConstants.ACTIVATE_CONFIRM,
        successText: UserDetailsConstants.ACTIVATE_SUCCESS,
        permission: permissions.activatePermission,
        onSet: (): void => {
          //@ts-ignore
          user.accountStatus.code = UserDetailsConstants.ACTIVATED_STATUS_CODE;
          //@ts-ignore
          user.accountStatus.label = UserDetailsConstants.ACTIVATED_STATUS_LABEL;
        },
        onModify: (): void => {}
      },
      DEACTIVATE: {
        operation: (password, passcode) => this.userService.deactivateUser$(params['id'], password, passcode),
        text: UserDetailsConstants.DEACTIVATE_LABEL,
        confirmText: UserDetailsConstants.DEACTIVATE_CONFIRM,
        successText: UserDetailsConstants.DEACTIVATE_SUCCESS,
        permission: permissions.deactivatePermission,
        onSet: (): void => {
          //@ts-ignore
          user.accountStatus.code = UserDetailsConstants.DEACTIVATED_STATUS_CODE;
          //@ts-ignore
          user.accountStatus.label = UserDetailsConstants.DEACTIVATED_STATUS_LABEL;
        },
        onModify: (): void => {}
      },
      RESEND: {
        operation: (password, passcode) => this.userService.resendEmail$(params['id'], password, passcode),
        text: UserDetailsConstants.RESEND_LABEL,
        confirmText: UserDetailsConstants.RESEND_CONFIRM,
        successText: UserDetailsConstants.RESEND_SUCCESS,
        permission: permissions.resendPermission,
        onSet: (): void => {},
        onModify: (): void => {}
      },
      UNLOCK: {
        operation: (password, passcode) => this.userService.unlock$(params['id'], password, passcode),
        text: UserDetailsConstants.UNLOCK_LABEL,
        confirmText: UserDetailsConstants.UNLOCK_CONFRIM,
        successText: UserDetailsConstants.UNLOCK_SUCCESS,
        onSet: (): void => {},
        onModify: (): void => {
          user.intruderLockOutUntil = null;
        }
      },
      RESETTFA: {
        operation: (password, passcode) => this.userService.resetTfa$(params['id'], password, passcode),
        text: UserDetailsConstants.RESET_TFA_LABEL,
        confirmText: UserDetailsConstants.RESET_TFA_CONFIRM,
        successText: UserDetailsConstants.RESET_TFA_SUCCESS,
        permission: permissions.resetTfaSecretPermission,
        onSet: (): void => {},
        onModify: (): void => {
          user.tfaConfirmationStatus = false;
        }
      }
    };
  }
}
