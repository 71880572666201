<div class="overlay" *ngIf="showOverlay()">
  <span class="mfa-close-button" (click)="onCancel()">
    <img [src]="iconUrl('times')" alt="times" />
  </span>
  <div class="content-container">
    <div class="spinner"></div>
    <div class="mfa-overlay-text-container">
      <p>You have pending changes that will be lost if you navigate away or refresh this page.</p>
      <p>Please wait until the update is complete.</p>
    </div>
  </div>
</div>
