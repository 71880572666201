import {NgFor, NgIf} from '@angular/common';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@jumio/portals.core';
import {AlertModule} from 'ngx-bootstrap/alert';
import {PipesModule} from 'public-shared/pipes/pipes.module';
import {ToDashesIfEmptyPipe} from 'public-shared/pipes/to-dashes-if-empty.pipe';
import {Alert, NotificationService} from 'public-shared/services/notification/notification.service';
import {Subscription} from 'rxjs';

UntilDestroy();
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less'],
  standalone: true,
  imports: [ToDashesIfEmptyPipe, PipesModule, NgIf, AlertModule, NgFor]
})
export class NotificationComponent implements OnInit {
  public notifications: Alert[] = [];

  public readonly subscription = new Subscription();

  constructor(
    public notificationService: NotificationService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.notificationService.state.subscribe(alerts => {
        this.notifications = [...alerts];
        this.cdr.detectChanges();
      })
    );
  }

  public close(notification: Alert): void {
    this.notificationService.close(notification);
  }

  public isErrorDescriptor(notification: Alert): boolean {
    return !!notification.errorDescriptor;
  }

  public toggleDetials(event: Event, notification: Alert): void {
    event.stopPropagation();
    notification.showDetails = !notification.showDetails;
    this.cdr.detectChanges();
  }
}
