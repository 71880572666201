<div class="alert-container" [ngClass]="type()" [class.form-addon]="isFormAddon()">
  <div class="alert-icon" [ngClass]="type()">
    {{ iconText() }}
  </div>

  @if (text()) {
    <div class="alert-message">{{ text() }}</div>
  }

  <div class="alert-multiline"></div>

  @if (url()) {
    <div class="alert-link">
      <a [href]="url()" target="_blank" rel="noopener noreferrer">{{ urlText() }}</a>
    </div>
  }

  <ng-content select=".alert-multiline"></ng-content>
</div>
