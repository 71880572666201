import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CodeLabel, CodeLabelIcon} from 'public-shared/models/code-label/code-label.dto';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {ReplayWorkflowsResult} from 'shared/interfaces/replay-workflows.dto';
import {AnnotatedImage} from 'shared/services/common/annotated-image.dto';
import {CacheService} from 'shared/services/common/cache.service';
import {AuthenticationsEndpoints, VerificationEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {IconConstants} from 'shared/services/icon.constants';
import {SearchService} from 'shared/services/search.service';
import {Agent} from 'shared/services/verification/agent.dto';
import {DocumentVerificationFields} from 'shared/services/verification/create-verification.dto';
import {EventLog} from 'shared/services/verification/details-dto/events-log.dto';
import {ImageHashRejectReasonRequest} from 'shared/services/verification/details-dto/image-hash.dto';
import {AuditLog} from './details-dto/audit-log.dto';
import {VerificationAuthentications} from './details-dto/verification-authentications.dto';
import {CustomerPortalVerificationDetailsResponse, VerificationDetails} from './details-dto/verification-details.dto';
import {VerificationDeleteRequest} from './verification-delete-request.dto';
import {VerificationFields, VerificationRejectReasonDetailsFields} from './verification-fields.dto';
import {VerificationFilter} from './verification-filter.dto';
import {VerificationListItem, Verification} from './verification.dto';
import {UntilDestroy} from '@jumio/portals.core';

@UntilDestroy()
@Injectable()
export class VerificationService extends BaseService implements SearchService, FilterService {
  private VERIFICATION_FIELDS_CACHE_KEY = 'VERIFICATION_FIELDS';
  private VERIFICATION_REJECT_REASON_DETAILS_FIELDS_CACHE_KEY = 'VERIFICATION_REJECT_REASON_DETAILS_FIELDS';

  public FILTER_CACHE_KEY = 'VERIFICATION_FILTER';
  public RESULTS_CACHE_KEY = 'VERIFICATION_RESULTS';

  public readonly subscription = new Subscription();

  constructor(
    protected override http: HttpClient,
    private cache: CacheService
  ) {
    super(http);
    this.baseUrl = VerificationEndpoints.BASE;
  }

  /**
   * Submits the filter form to backend.
   * After receives results, creates a JumioPage instance with the correct icons set.
   * @param filter The filter form.
   */
  public filter$(filter: Partial<VerificationFilter>): Observable<JumioPage<VerificationListItem>> {
    return this.post$<any>(VerificationEndpoints.FILTER, filter).pipe(
      map(result => {
        const statuses = this.getFieldLabelMap('status');
        const documentTypes = this.getFieldLabelMap('documentType');
        for (const item of result.list) {
          item.idScanStatus = new CodeLabelIcon(
            item.idScanStatus,
            statuses[item.idScanStatus] || item.idScanStatus,
            IconConstants.STATUS_ICON_MAP[item.idScanStatus as keyof typeof IconConstants.STATUS_ICON_MAP] || IconConstants.DEF_ICON
          );
          item.type = new CodeLabelIcon(
            item.type,
            documentTypes[item.type] || item.type,
            IconConstants.DOCUMENT_TYPE_ICON_MAP[item.type as keyof typeof IconConstants.DOCUMENT_TYPE_ICON_MAP] || IconConstants.DEF_ICON
          );
          item.country = new CodeLabelIcon(
            item.country ? item.country.code : '',
            item.country ? item.country.label : '',
            item.country && item.country.alpha2 ? 'flag flag-' + item.country.alpha2.toLowerCase() : IconConstants.DEF_ICON
          );
          item.updateInfoCreatedAt = item.updateInfoCreatedAt ? new Date(item.updateInfoCreatedAt) : undefined;
        }
        return result;
      })
    );
  }

  /**
   * Sends a delete request for a given verification. Returns the updated Verification object.
   * @param scanReference The ID of the verification.
   * @param deleteRequest An object with optional fields to accompany the request.
   */
  public deleteVerification$(scanReference: string, deleteRequest?: VerificationDeleteRequest): Observable<Verification> {
    return this.post$<Verification>(VerificationEndpoints.DELETE(scanReference), deleteRequest || {});
  }

  /**
   * Sends a wipe out request for a given verification.
   * @param scanReference The ID of the verification.
   */
  public wipeOutVerification$(scanReference: string, transactionReference: string): Observable<Verification> {
    return this.delete$<Verification>(VerificationEndpoints.VERIFICATION_WITH_TRANSACTION(scanReference, transactionReference));
  }

  /**
   * Receives the filter form fields from backend.
   */
  public getVerificationFields$(): Observable<VerificationFields> {
    const fn$ = this.get$<VerificationFields>(VerificationEndpoints.INIT);
    return this.cache.cacheFunction$<VerificationFields>(this.VERIFICATION_FIELDS_CACHE_KEY, fn$);
  }

  /**
   * Receives the reject reason details filter form fields from backend.
   */
  public getVerificationRejectReasonDetailsFields$(): Observable<VerificationRejectReasonDetailsFields[]> {
    const fn$ = this.get$<VerificationRejectReasonDetailsFields[]>(VerificationEndpoints.REJECT_REASON_DETAILS);
    return this.cache.cacheFunction$<VerificationRejectReasonDetailsFields[]>(
      this.VERIFICATION_REJECT_REASON_DETAILS_FIELDS_CACHE_KEY,
      fn$
    );
  }

  public getDocumentVerificationFields$(): Observable<DocumentVerificationFields> {
    /* TODO remove this after backend service is ready */
    return of({
      countries: [],
      documentTypes: []
    });
    /*    if (!this.createDocumentVerificationFields.value) {
      this.get<DocumentVerificationFields>(VerificationEndpoints.CREATE_DOCUMENT_INIT).subscribe(result => {
        this.createDocumentVerificationFields.next(result);
      })
    }
    return this.createDocumentVerificationFields.asObservable();*/
  }

  public createDocumentVerification$(): Observable<string> {
    // TODO remove this after backend service is ready
    return of('abc');
    // return this.post<CreateVerificationResponse>(VerificationEndpoints.CREATE_DOCUMENT, entity).map(result => result.tokenUrl);
  }

  /**
   * Receives the details of the given verification/transaction.
   * @param scanReference The public GUID of the verification.
   */
  public getVerificationDetails$(scanReference: string, transactionReference: string): Observable<VerificationDetails> {
    return this.get$<VerificationDetails>(VerificationEndpoints.VERIFICATION_WITH_TRANSACTION(scanReference, transactionReference));
  }

  /**
   * Customer portal verification details
   * @param scanReference
   */
  public getCustomerPortalVerificationDetails$(
    scanReference: string,
    transactionReference: string
  ): Observable<CustomerPortalVerificationDetailsResponse> {
    return this.get$<CustomerPortalVerificationDetailsResponse>(
      VerificationEndpoints.VERIFICATION_WITH_TRANSACTION(scanReference, transactionReference)
    );
  }

  /**
   * Receives the images of the given verification/transaction.
   * @param scanReference The public GUID of the verification.
   * @returns {Observable<AnnotatedImage[]>} An Observable of the array of images.
   */
  public getVerificationDetailsImages$(scanReference: string): Observable<AnnotatedImage[]> {
    return this.get$<AnnotatedImage[]>(VerificationEndpoints.IMAGES(scanReference));
  }

  public getVerificationDetailsImageWithClassifier$(scanReference: string, classifier: string): Observable<any> {
    return this.get$<any>(VerificationEndpoints.IMAGE_WITH_CLASSIFIER(scanReference, classifier));
  }

  /**
   * Receives the array of audit log details of a given verification from the server.
   * @param scanReference The ID of the given verification.
   * @returns {Observable<AuditLog[]>} An Observable of the array of audit log elements.
   */
  public getAuditLog$(scanReference: string): Observable<AuditLog[]> {
    return this.get$<AuditLog[]>(VerificationEndpoints.AUDIT_LOG(scanReference));
  }

  /**
   * Receives the array of events log details of a given verification from the server.
   * @param {string} scanReference The ID of the given verification.
   * @returns {Observable<EventLog[]>} An Observable of the array of event log elements.
   */
  public getEventsLog$(scanReference: string): Observable<EventLog[]> {
    return this.get$<EventLog[]>(VerificationEndpoints.EVENTS_LOG(scanReference));
  }

  public getAuthentications$(scanReference: string, transactionReference: string): Observable<VerificationAuthentications[]> {
    return this.http.get<VerificationAuthentications[]>(
      AuthenticationsEndpoints.BASE + AuthenticationsEndpoints.AUTHENTICATIONS(scanReference, transactionReference)
    );
  }

  /**
   * Download csv by filter, get result, as blob.
   * @param filter The verification filter
   * @returns {Observable<Blob>} Returns the blob as Observable
   */
  public downloadCsv$(filter: VerificationFilter): Observable<Blob> {
    return this.http.post(this.baseUrl + VerificationEndpoints.CSV, filter, {responseType: 'text'}).pipe(map(BaseService.mapToCsv));
  }

  /**
   * Download PDF from Document Verification
   */
  public downloadDVPDF$(id: string, classifier: string): Observable<Blob> {
    return this.http
      .get<string>(this.baseUrl + VerificationEndpoints.IMAGE_WITH_CLASSIFIER(id, classifier), {responseType: 'blob' as 'json'})
      .pipe(map(BaseService.mapToPDF));
  }

  /**
   * Sends a search query to the backend for receiving a list of agents.
   * @param query The query string we send towards the backend,
   * @returns {Observable<Agent[]>} An Observable of agents that met the search query.
   */
  public search$(query: string): Observable<Array<Agent>> {
    return this.post$<Agent[]>(VerificationEndpoints.AGENT_SEARCH, new AgentSearchQuery(query));
  }

  /**
   * Sends a delete request to remove the given verification from the image hash service.
   * @param {string} publicGuid The publicGuid of the given verification.
   * @returns {Observable<void>} An empty Observable.
   */
  public removeFromImageHash$(publicGuid: string): Observable<void> {
    return this.delete$(VerificationEndpoints.IMAGE_HASH(publicGuid));
  }

  /**
   * Sends an approval request to the backend setting the given verification usable in the image hash service.
   * @param {string} publicGuid The publicGuid of the given verification.
   * @returns {Observable<void>} An empty Observable.
   */
  public setImageHashUsable$(publicGuid: string): Observable<void> {
    return this.post$<void>(VerificationEndpoints.IMAGE_HASH_APPROVE(publicGuid), {});
  }

  /**
   * Sends a rejection request to the backend setting the given verificaiton unusable in the image hash service.
   * @param {string} publicGuid The publicGuid of the given verificataion.
   * @param {ImageHashRejectReasonRequest} rejectReasonRequest
   * @returns {Observable<void>}
   */
  public setImageHashUnusable$(publicGuid: string, rejectReasonRequest: ImageHashRejectReasonRequest): Observable<void> {
    return this.post$<void>(VerificationEndpoints.IMAGE_HASH_REJECT(publicGuid), rejectReasonRequest);
  }

  public replayScan$(verificationPublicGuid: string): Observable<ReplayWorkflowsResult> {
    return this.http.post<ReplayWorkflowsResult>(
      this.baseUrl + VerificationEndpoints.RESCAN(verificationPublicGuid),
      verificationPublicGuid
    );
  }

  private getFieldLabelMap(property: string): Record<string, unknown> {
    const verificationFields = this.cache.hasKey(this.VERIFICATION_FIELDS_CACHE_KEY)
      ? this.cache.getItem(this.VERIFICATION_FIELDS_CACHE_KEY)
      : {};
    return CodeLabel.getCodeLabelMap(verificationFields[property]);
  }
}

/**
 * A helper class to contain the search query field.
 */
export class AgentSearchQuery {
  constructor(public query: string) {}
}
