import {AfterViewChecked, Component, ContentChild, ElementRef, Input, Optional} from '@angular/core';
import {NgForm, NgModelGroup} from '@angular/forms';
import {AbstractFormGroupComponent} from 'public-shared/components/form-group/abstract/abstract-form-group.component';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.less']
})
export class FormGroupComponent extends AbstractFormGroupComponent implements AfterViewChecked {
  @Input() public name: string | undefined;
  @Input() public classes: string | undefined;
  @ContentChild('formElement', {read: ElementRef}) formElement: ElementRef | undefined;

  constructor(
    form: NgForm,
    @Optional() modelGroup: NgModelGroup,
    private eleRef: ElementRef
  ) {
    super(form, modelGroup);
  }

  ngAfterViewChecked(): void {
    const hasAlert = !!this.eleRef?.nativeElement?.querySelector('j4-alert');
    if (hasAlert) {
      this.formElement?.nativeElement?.classList?.add('has-alert');
    } else {
      this.formElement?.nativeElement?.classList?.remove('has-alert');
    }
  }
}
