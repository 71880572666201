import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {JumioPageFilter} from 'shared/components/table/dto/jumio-page-filter';
import {ReplayWorkflowsResult} from 'shared/interfaces/replay-workflows.dto';
import {CacheService} from 'shared/services/common/cache.service';
import {FilterService} from 'shared/services/filter.service';
import {WorkflowDatatableElement} from 'shared/services/workflow-executions/workflow-execution-datatable-element.class';
import {WorkflowFilterFields} from 'shared/services/workflow-executions/workflow-execution-filter.dto';
import {WorkflowExecutionsEndpoints} from '../endpoint-constants';
import {AuditLog} from '../verification/details-dto/audit-log.dto';
import {EventLog} from '../verification/details-dto/events-log.dto';
import {KYXAPTransactionDetail, WorkflowDetail} from './workflow-executions.interfaces';

@Injectable()
export class WorkflowExecutionsService extends BaseService implements FilterService {
  public FILTER_CACHE_KEY = 'WORKFLOWS_FILTER';
  public FILTER_INIT_CACHE_KEY = 'WORKFLOWS_FILTER_INIT';
  public RESULTS_CACHE_KEY = 'WORKFLOWS_RESULTS';

  public TRANSACTION_FILTER_CACHE_KEY = 'TRANSACTIONS_FILTER';
  public TRANSACTION_FILTER_INIT_CACHE_KEY = 'TRANSACTIONS_FILTER_INIT';
  public TRANSACTION_RESULTS_CACHE_KEY = 'TRANSACTION_RESULTS';
  constructor(
    protected override http: HttpClient,
    private cache: CacheService
  ) {
    super(http);
  }

  public getWorkflow$(id: string): Observable<WorkflowDetail> {
    return this.get$<WorkflowDetail>(WorkflowExecutionsEndpoints.DETAIL(id));
  }

  // Endpoint returns 'errorcode = 22000' when wf has not run completly - completedAt is null
  public deleteWorkflow$(id: string): Observable<void> {
    return this.delete$<void>(WorkflowExecutionsEndpoints.DETAIL(id));
  }

  public getKYXTransaction$(id: string): Observable<KYXAPTransactionDetail> {
    return this.get$<KYXAPTransactionDetail>(WorkflowExecutionsEndpoints.DETAIL(id));
  }

  /**
   * Receives the array of events log details of a given workflow from the server.
   * @param {string} id The ID of the given workflow.
   * @returns {Observable<EventLog[]>} An Observable of the array of event log elements.
   */
  public getEventsLog$(id: string): Observable<EventLog[]> {
    return this.get$<EventLog[]>(WorkflowExecutionsEndpoints.EVENTS_LOG(id));
  }

  public getAuditLog$(id: string): Observable<AuditLog[]> {
    return this.get$<AuditLog[]>(WorkflowExecutionsEndpoints.AUDIT_LOG(id));
  }

  public fetchImage$(href: string): Observable<{binary: string; mimeType: string}> {
    return this.http.get<{binary: string; mimeType: string}>(href);
  }

  /**
   * Required for IFilterService
   * Sending the filter values to the backend, receiving the filtered results
   * @param filter
   */
  public filter$(filter: JumioPageFilter): Observable<JumioPage<WorkflowDatatableElement>> {
    return this.post$<JumioPage<WorkflowDatatableElement>>(WorkflowExecutionsEndpoints.SEARCH, filter).pipe(
      map(result => {
        const mappedResults: WorkflowDatatableElement[] = [];
        //@ts-ignore
        for (const workflow of result?.list) {
          mappedResults.push(new WorkflowDatatableElement(workflow));
        }
        return {list: mappedResults, total: result.total} as JumioPage<WorkflowDatatableElement>;
      })
    );
  }

  public search$(): Observable<never> {
    return EMPTY;
  }

  /**
   * Receives the additional filters for fields from backend.
   */
  public getWorkflowFields$(): Observable<WorkflowFilterFields> {
    const fn$ = this.get$<WorkflowFilterFields>(WorkflowExecutionsEndpoints.INIT);
    return this.cache.cacheFunction$<WorkflowFilterFields>(this.FILTER_INIT_CACHE_KEY, fn$);
  }

  public replayWorkflow$(workflowExecutionId: string): Observable<ReplayWorkflowsResult> {
    return this.http.post<ReplayWorkflowsResult>(WorkflowExecutionsEndpoints.RESCAN(workflowExecutionId), workflowExecutionId);
  }
}
