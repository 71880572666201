import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TokenValidationEndpoints} from 'public-shared/models/endpoints/base-auth-endpoint.constants';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {PublicSecurityContextHolder} from 'public-shared/services/security/public-security-context-holder';
import {Observable} from 'rxjs';

@Injectable()
export class TokenService extends BaseService {
  protected logoutCalled = false;
  public holdPassword: string | undefined;
  public holdEmail: string | undefined;

  constructor(
    protected securityContextHolder: PublicSecurityContextHolder,
    protected override http: HttpClient
  ) {
    super(http);
    this.baseUrl = TokenValidationEndpoints.BASE;
  }

  public isTokenValid$(): Observable<boolean> {
    return this.get$<boolean>(TokenValidationEndpoints.TOKEN_VALIDATION);
  }
}
