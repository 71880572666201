import {WidgetsIds} from '../../dashboard/dashboard.component.dto';

export interface IWidgets {
  name: string;
  id: number;
  widgets?: IWidgets[];
  selected?: boolean;
  indeterminate?: boolean;
  parent?: IWidgets;
}

export const CustomerDashboard: IWidgets[] = [
  {
    id: WidgetsIds.GLOBAL_RISK_ANALYSIS,
    name: 'General',
    widgets: [
      {
        id: WidgetsIds.GLOBAL_RISK_ANALYSIS,
        name: 'Global Risk Analysis'
      }
    ]
  },
  {
    id: WidgetsIds.KYC_TRANSACTIONS,
    name: 'Identity / KYC',
    widgets: [
      {
        id: WidgetsIds.KYC_TRANSACTIONS,
        name: 'KYC Transactions'
      },
      {
        id: WidgetsIds.NEW_CUSTOMERS,
        name: 'New Customers'
      },
      {
        id: WidgetsIds.CHANNEL_SOURCE,
        name: 'Channel Source'
      },
      {
        id: WidgetsIds.TRANSACTIONS_PER_DOCUMENT_TYPE,
        name: 'Transactions per Document Type'
      },
      {
        id: WidgetsIds.TRANSACTIONS_PER_PRODUCT,
        name: 'Transactions per Product'
      },
      {
        id: WidgetsIds.CAPABILITY_USAGE,
        name: 'Capability Usage'
      }
    ]
  }
];

export const expand_more = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M24 24H0V0h24v24z" fill="none"/>
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/>
   </svg>`;
export const chevron_right = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M8 16a.999.999 0 01-.707-1.707L11.586 10 7.293 5.707a.999.999 0 111.414-1.414l5 5a.999.999 0 010 1.414l-5 5A.997.997 0 018 16z" fill="#CECFDF"/>
   </svg>`;
