import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {J4TenantsEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {J4GroupFilter} from 'shared/services/j4-groups/j4-groups.dto';
import {J4Tenant, J4TenantDetails, TenantSettings} from 'shared/services/j4-tenants/j4-tenant.dto';
import {J4SearchTenantFilter, J4TenantsFilter} from 'shared/services/j4-tenants/j4-tenants-filter.dto';
import {J4UserFilter} from 'shared/services/j4-users/j4-users-filter.dto';
import {J4User} from 'shared/services/j4-users/j4-users.dto';
import {SearchService} from 'shared/services/search.service';
@Injectable()
export class J4TenantsService extends BaseService implements FilterService, SearchService {
  /**
   * Set base url
   */
  constructor(
    protected override http: HttpClient,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>
  ) {
    super(http);
    this.baseUrl = J4TenantsEndpoints.BASE;
  }

  public addTenant$(tenant: J4Tenant, password: string): Observable<J4Tenant> {
    return this.post$<J4Tenant>(J4TenantsEndpoints.TENANTS, new EntityWithPassword(tenant, password));
  }

  /**
   * Get filters
   */
  public initFilter$(): Observable<J4Tenant[]> {
    return this.get$<J4Tenant[]>(J4TenantsEndpoints.TENANTS);
  }

  /**
   * Required for FilterService
   * Sending the filter values to the backend, receiving the filtered results
   */

  public filter$(filter: J4SearchTenantFilter): Observable<JumioPage<J4Tenant>> {
    return this.post$<JumioPage<J4Tenant>>(J4TenantsEndpoints.FILTER, filter);
  }

  public search$(query: string): Observable<J4Tenant[]> {
    return this.get$<J4Tenant[]>(J4TenantsEndpoints.TENANTS).pipe(map(result => result.filter(item => item.name?.includes(query))));
  }

  public getGroupsByTenant$(tenantId: string, filter: J4TenantsFilter | null = null): Observable<JumioPage<J4GroupFilter>> {
    let url = `${J4TenantsEndpoints.GROUPS(tenantId)}`;
    if (filter) {
      const offset = filter.offset! / 10;
      url += `?offset=${offset}&pageSize=${filter.pageSize}`;
    }
    return this.get$<JumioPage<J4GroupFilter>>(url);
  }

  public getUsersByTenant$(tenantId: string, filter: J4UserFilter): Observable<JumioPage<J4User>> {
    //@ts-ignore
    const offset = filter.offset / 10;
    const url = `${J4TenantsEndpoints.USERS(tenantId)}?offset=${offset}&pageSize=${filter.pageSize}&disabledUsers=true`;
    return this.get$<JumioPage<J4User>>(url);
  }

  public getTenantById$(tenantId: string): Observable<J4Tenant> {
    return this.get$<J4Tenant>(J4TenantsEndpoints.TENANTS_BY_ID(tenantId));
  }

  public updateTenantSettings$(emails: TenantSettings, password: string): Observable<void> {
    return this.post$<void>(J4TenantsEndpoints.TENANTS_SETTINGS, new EntityWithPassword(emails, password));
  }

  public putTenantDetails$(tenantDetails: J4TenantDetails, password: string): Observable<void> {
    return this.put$<void>(J4TenantsEndpoints.TENANTS, new EntityWithPassword(tenantDetails, password));
  }
}
